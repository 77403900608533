import {Controller} from '@hotwired/stimulus';
import {createHTMLElementFromTemplate} from 'app/utils/dom';
import {throttle} from "throttle-debounce";
import {Typed} from "app/stimulus/typed-stimulus";
import {AsStimulusController} from "app/stimulus/as-stimulus-controller";

@AsStimulusController('responsive-button-group')
export default class extends Typed(
    Controller<HTMLElement>, {
        targets: {
            dropdown: HTMLElement,
            dropdownList: HTMLElement,
            button: HTMLElement,
            buttonTemplate: HTMLTemplateElement,
            dropdownItem: HTMLElement,
        },
        classes: [
            'hasCollapsedItems',
            'hasOneVisibleItem',
        ] as const,
    }
)
{
    private containerResizeObserver!: ResizeObserver;
    private containerMutationObserver!: MutationObserver;

    public initialize() {
        this.resize = throttle(100, this.resize.bind(this));
        this.containerResizeObserver = new ResizeObserver(() => this.resize());
        this.containerMutationObserver = new MutationObserver(() => this.resize());
    }

    public connect() {
        this.containerResizeObserver.observe(this.element);
        this.containerMutationObserver.observe(this.element, {childList: true});
        this.resize();
    }

    public disconnect() {
        this.containerResizeObserver.disconnect();
        this.containerMutationObserver.disconnect();
    }

    public resize(): void {
        this.element.classList.remove(this.hasCollapsedItemsClass);
        this.element.classList.remove(this.hasOneVisibleItemClass);
        if (this.isOverflowing()) {
            const visibleButtons = this.getVisibleButtons();
            if (this.buttonTargets.length > 1 && !this.hasHiddenButtons()) {
                this.dropdownTarget.style.display = '';
            }
            for (let i = visibleButtons.length - 1; i >= 1; i--) {
                const button = visibleButtons[i];
                button.style.display = 'none';
                if (!this.isOverflowing()) {
                    break;
                }
            }
        } else {
            const hiddenButtons = this.getHiddenButtons();
            for (let i = 0; i < hiddenButtons.length; i++) {
                const button = hiddenButtons[i];
                if (i + 1 === hiddenButtons.length) {
                    this.dropdownTarget.style.display = 'none';
                    button.style.visibility = 'hidden';
                    button.style.display = '';
                    if (this.isOverflowing()) {
                        this.dropdownTarget.style.display = '';
                        button.style.visibility = '';
                        button.style.display = 'none';
                        break;
                    }
                    button.style.visibility = '';
                    break;
                }
                button.style.visibility = 'hidden';
                button.style.display = '';
                if (this.isOverflowing()) {
                    button.style.visibility = '';
                    button.style.display = 'none';
                    break;
                }
                button.style.visibility = '';
            }
        }
        this.getVisibleButtons().length === 1
            ? this.element.classList.add(this.hasOneVisibleItemClass)
            : this.element.classList.remove(this.hasOneVisibleItemClass);
        this.hasHiddenButtons()
            ? this.element.classList.add(this.hasCollapsedItemsClass)
            : this.element.classList.remove(this.hasCollapsedItemsClass);
    }

    public setupCollapsedItemsDropdown(): void {
        if (!this.hasDropdownListTarget) {
            return;
        }
        for (let i = 0; i < this.buttonTargets.length; i++) {
            const button = this.buttonTargets[i];
            if (button.style.display !== 'none') {
                continue;
            }
            const buttonTemplate = this.buttonTemplateTargets[i];
            const dropdownItem = createHTMLElementFromTemplate(buttonTemplate);
            this.dropdownListTarget.appendChild(dropdownItem);
        }
    }

    private getVisibleButtons(): HTMLElement[] {
        return this.buttonTargets.filter(button => button.style.display !== 'none');
    }

    private getHiddenButtons(): HTMLElement[] {
        return this.buttonTargets.filter(button => button.style.display === 'none');
    }

    private hasHiddenButtons(): boolean {
        return this.getHiddenButtons().length > 0;
    }

    private isOverflowing(): boolean {
        return this.element.scrollWidth > this.element.offsetWidth;
    }
}
