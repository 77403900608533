import {Controller} from "@hotwired/stimulus";
import {AsStimulusController} from "app/stimulus/as-stimulus-controller";
import {Component} from "@symfony/ux-live-component";
import {componentsLoader} from "app/service/components-loader";
import {LiveComponentAwareController} from "app/stimulus/live-component-aware-controller";

@AsStimulusController('contract-rs-fwd-investment-questionnaire-proposal-form')
export default class extends LiveComponentAwareController(Controller<HTMLElement>)
{
    private onComponentRenderFinished!: () => void;

    public initialize(): void {
        this.onComponentRenderFinished = this.initJs.bind(this);
    }

    public liveComponentConnect(component: Component) {
        component.on('render:finished', this.onComponentRenderFinished);
    }

    public liveComponentDisconnect(component: Component) {
        component.off('render:finished', this.onComponentRenderFinished);
    }

    private initJs(): void {
        componentsLoader.load(this.element);
    }
}
