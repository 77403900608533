import {Controller} from '@hotwired/stimulus';

export default class FormHelpButtonController extends Controller
{
    private targetValue!: string;
    private _targetElement: HTMLElement | null = null;
    private targetElementLoaded: boolean = false;

    static values = {
        target: String,
    };

    public toggleHelp(): void {
        const targetElement = this.targetElement;
        if (targetElement === null) {
            return;
        }
        const computedStyle = window.getComputedStyle(targetElement);
        computedStyle.display === 'none' ? $(targetElement).slideDown() : $(targetElement).slideUp();
    }

    private get targetElement(): HTMLElement | null {
        if (!this.targetElementLoaded) {
            this._targetElement = document.getElementById(this.targetValue);
            this.targetElementLoaded = true;
        }
        return this._targetElement;
    }
}
