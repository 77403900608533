import Component from 'app/components/component';
import 'select2';
import 'select2/dist/css/select2.min.css';
import {
    DataFormat,
    GroupedDataFormat,
    IdTextPair,
    LoadingData,
    OptGroupData,
    Options,
    ProcessedResult,
    SearchOptions,
} from 'select2';
import 'select2/dist/js/i18n/cs';
import InvalidArgumentException from 'app/errors/invalid-argument-exception';
import elementAttrChangeObserver from 'app/service/element-attr-change-observer';
import NotImplementedException from 'app/errors/not-implemented-exception';

export default class Select extends Component<Config, HTMLSelectElement>
{
    private readonly optionsDatabase: { [_key: string]: OptionData };

    public static readonly defaultConfig: Partial<Config> = {
        placeholder: null,
        showSearch: true,
        url: null,
        emptyValue: null,
    };

    public constructor(element: HTMLSelectElement, config: Config) {
        super(element, config, false);
        this.optionsDatabase = {};
        this.initialize();
    }

    protected initialize() {
        this.buildOptionsData();
        const select2Config = this.assembleSelect2Options();
        this.$element.select2(select2Config);
        this.addStateClass(this.isMultiple() ? 'select2-container--multiple' : 'select2-container--single');
        if (select2Config.allowClear === true) {
            this.addStateClass('select2-container--has-clear-btn');
        }
        if (this.config.customStateClass !== null) {
            this.addStateClass(this.config.customStateClass);
        }
        if (this.config.placeholder !== null && !this.isMultiple() && this.$element.find('option[selected]').length === 0) {
            this.$element.val('').trigger('change');
        }
        if (!String.isNullOrWhiteSpace(this.config.emptyValue) && !this.isMultiple() && String.isNullOrWhiteSpace(this.getSingleSelectValue())) {
            this.$element.val(this.config.emptyValue as string).trigger('change');
        }
        this.$element.on('change.select2', () => this.onSelectValueChange());
        this.setupCorrectIsEmptyState();
        elementAttrChangeObserver.observe(this.element, 'readonly', (): void => {
            if (this.isReadOnly()) {
                this.disable();
                return;
            }
            this.enable();
        });
    }

    public getComponentName(): string {
        return 'Select';
    }

    private assembleSelect2Options(): Options {
        const select2Options: Options = {
            language: APP_CONFIG.common.locale,
            minimumResultsForSearch: !this.config.showSearch || (!this.isMultiple() && this.optionsLength() < 5)
                ? -1
                : 3,
            width: '100%',
            templateResult: (item) => this.templateResult(item),
            templateSelection: (item) => this.templateSelection(item),
            // @ts-ignore
            matcher: (params, data) => {
                // @ts-ignore
                let match = this.getDefaultMatcher()(params, data);
                // @ts-ignore
                if (match === null && typeof data.id === 'string' && typeof params.term === 'string' && params.term.trim().length > 0 && typeof params.term.includes === 'function') {
                    // @ts-ignore
                    const optionData = this.getOptionData(data.id);
                    const locale = APP_CONFIG.common.locale;
                    const a = params.term.toLocaleUpperCase(locale);
                    if (optionData !== null && optionData.keywords.length > 0) {
                        for (const keyword of optionData.keywords) {
                            const b = keyword.toString().toLocaleUpperCase(locale);
                            if (b.includes(a)) {
                                // @ts-ignore
                                match = $.extend(true, {}, data);
                                break;
                            }
                        }
                    }
                }
                return match;
            },
        };
        if (this.config.placeholder !== null) {
            select2Options.placeholder = this.config.placeholder;
            if (!this.isMultiple() && !this.isDisabled()) {
                select2Options.allowClear = true;
            }
        }
        if (this.isDisabled() || this.isReadOnly()) {
            select2Options.disabled = true;
        }
        if (this.config.url !== null) {
            select2Options.minimumInputLength = 3;
            select2Options.ajax = {
                url: this.config.url,
                delay: 500,
                cache: true,
                dataType: 'json',
                data: (params) => {
                    return {
                        query: params.term,
                        page: params.page,
                    };
                },
                processResults: (response: PaginatedDataSourceResponse): ProcessedResult => {
                    const mappedItems: { id: string; text: string; }[] = [];
                    response.items.forEach((item) => {
                        if (typeof item.image !== 'string') {
                            item.image = null;
                        }
                        if (!Array.isArray(item.images)) {
                            item.images = [];
                        }
                        if (!Array.isArray(item.keywords)) {
                            item.keywords = [];
                        }
                        mappedItems.push({id: item.value, text: item.label});
                        this.setOptionData(item.value, item);
                    });
                    return {
                        results: mappedItems,
                        pagination: {
                            more: response.pagination.hasNextPage,
                        },
                    };
                },
            };
        }
        return select2Options;
    }

    private buildOptionsData(): void {
        this.$element.find('option').each((_, element) => {
            const option = $(element);
            const image = option.data('image');
            const value = option.attr('value') as string;
            const keywords = option.data('keywords');
            const images = option.data('images');
            this.setOptionData(value, {
                value: value,
                image: typeof image === 'string' ? image : null,
                label: option.text(),
                keywords: Array.isArray(keywords) ? keywords : [],
                images: Array.isArray(images) ? images : [],
            });
        });
    }

    private setOptionData(key: string, optionData: OptionData): void {
        this.optionsDatabase[key] = optionData;
    }

    private getOptionData(key: string): OptionData | null {
        const hit = this.optionsDatabase[key];
        return typeof hit === 'undefined' ? null : hit as OptionData;
    }

    private templateSelection(item: IdTextPair | LoadingData | DataFormat | GroupedDataFormat): string | JQuery {
        if (typeof item.id === 'undefined') {
            return item.text;
        }
        const data = this.getOptionData(item.id as string);
        if (data === null || (data.image === null && data.images.length === 0)) {
            return item.text;
        }
        return this.buildTemplatedItem(data);
    }

    private templateResult(item: LoadingData | DataFormat | GroupedDataFormat): string | JQuery {
        if (typeof item.id === 'undefined') {
            return item.text;
        }
        const data = this.getOptionData(item.id as string);
        if (data === null || (data.images.length === 0 && data.image === null)) {
            return item.text;
        }
        return this.buildTemplatedItem(data);
    }

    private buildTemplatedItem(optionData: OptionData): JQuery {
        if (optionData.image === null && optionData.images.length === 0) {
            throw new InvalidArgumentException('Provided option data is supposed to have image / images.');
        }
        if (optionData.image !== null) {
            return $('<span class="select__item">')
                .append($('<span class="select__item-image-container">')
                    .append($('<img class="select_item-image">')
                        .attr('src', optionData.image)
                        .attr('alt', optionData.label),
                    ))
                .append($('<span class="select_item-text">')
                    .text(optionData.label));
        }
        if (optionData.images.length > 0) {
            const imagesContainer = $('<span class="select__item-images-container">');
            for (const image of optionData.images) {
                imagesContainer.append($('<img class="select_item-image">')
                    .attr('src', image)
                    .attr('alt', optionData.label));
            }
            return $('<span class="select__item">')
                .append(imagesContainer)
                .append($('<span class="select_item-text">')
                    .text(optionData.label));
        }
        throw new NotImplementedException();
    }

    public isDisabled(): boolean {
        return this.$element.attr('disabled') !== undefined;
    }

    public isMultiple(): boolean {
        return this.$element.attr('multiple') !== undefined;
    }

    public optionsLength(): number {
        return this.$element.find('option').length;
    }

    private getSelect2Dropdown(): JQuery<HTMLElement> {
        return this.$element.data('select2').$dropdown;
    }

    private getSelect2Container(): JQuery<HTMLElement> {
        return this.$element.data('select2').$container;
    }

    private addStateClass(className: string): void {
        this.getSelect2Container().addClass(className);
        this.getSelect2Dropdown().addClass(className);
    }

    private removeStateClass(className: string): void {
        this.getSelect2Container().removeClass(className);
        this.getSelect2Dropdown().removeClass(className);
    }

    private getSingleSelectValue(): string | null {
        return this.$element.val() as string | null;
    }

    private getMultipleSelectValue(): string[] {
        return this.$element.val() as string[];
    }

    private setNotEmptyState(): void {
        this.removeStateClass('select2-container--is-empty');
        this.addStateClass('select2-container--is-not-empty');
    }

    private setEmptyState(): void {
        this.addStateClass('select2-container--is-empty');
        this.removeStateClass('select2-container--is-not-empty');
    }

    private onSelectValueChange(): void {
        this.setupCorrectIsEmptyState();
        this.element.dispatchEvent(new Event('change', {bubbles: true}));
    }

    private setupCorrectIsEmptyState(): void {
        if (this.isMultiple()) {
            const value = this.getMultipleSelectValue();
            if (value.length === 0) {
                this.setEmptyState();
            } else {
                this.setNotEmptyState();
            }
        } else {
            const value = this.getSingleSelectValue();
            if (value === null || value.trim() === '') {
                this.setEmptyState();
            } else {
                this.setNotEmptyState();
            }
        }
    }

    public disable(): void {
        this.$element.prop('disabled', true);
    }

    public enable(): void {
        this.$element.prop('disabled', false);
    }

    private getDefaultOptions(): Options {
        // @ts-ignore
        return this.$element.select2.defaults.defaults as Options;
    }

    private getDefaultMatcher(): Matcher {
        return this.getDefaultOptions().matcher as Matcher;
    }
}

type Matcher = (params: SearchOptions, data: OptGroupData | OptionData) => OptionData | OptGroupData | null;

type Config = {
    placeholder: string | null;
    showSearch: boolean;
    url: string | null;
    customStateClass: string | null;
    emptyValue: string | null;
}

type OptionData = {
    value: string;
    image: string | null;
    images: string[];
    label: string;
    keywords: string[];
}

type PaginatedDataSourceResponse = {
    items: OptionData[];
    pagination: {
        page: number;
        pagesCount: number;
        hasNextPage: boolean;
    }
}
