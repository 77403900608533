import {Controller} from '@hotwired/stimulus';
import {Component, getComponent, LiveController} from "@symfony/ux-live-component";

type Constructor<T = {}> = new (...args: any[]) => T;

interface LiveComponentAwareController
{
    liveComponentConnect(component: Component): void;

    liveComponentDisconnect(component: Component): void;
}

const elementToControllerMap = new WeakMap<HTMLElement, LiveComponentAwareController>();
const controllerToComponentMap = new WeakMap<LiveComponentAwareController, Component>();
const connectedElements = new WeakSet<HTMLElement>();

type CustomEventDetail = {
    controller: LiveController,
    component: Component,
};

async function onConnect(event: CustomEvent<CustomEventDetail>): Promise<void> {
    const element = event.detail.controller.element;
    if (!(element instanceof HTMLElement)) {
        return;
    }
    const controller = elementToControllerMap.get(element);
    if (typeof controller === 'undefined') {
        return;
    }
    const currentComponent = await getComponent(element);
    const oldComponent = controllerToComponentMap.get(controller);
    if (oldComponent !== currentComponent) {
        controllerToComponentMap.set(controller, currentComponent);
        if (typeof controller.liveComponentConnect === 'function') {
            controller.liveComponentConnect(currentComponent);
        }
    }
}

async function onDisconnect(event: CustomEvent<CustomEventDetail>): Promise<void> {
    const element = event.detail.controller.element;
    if (!(element instanceof HTMLElement)) {
        return;
    }
    const controller = elementToControllerMap.get(element);
    if (typeof controller === 'undefined') {
        return;
    }
    const oldComponent = controllerToComponentMap.get(controller);
    if (typeof oldComponent !== 'undefined') {
        controllerToComponentMap.delete(controller);
        if (typeof controller.liveComponentDisconnect === 'function') {
            controller.liveComponentDisconnect(oldComponent);
        }
    }
}

function connectListenersToElement(element: HTMLElement): void {
    // @ts-ignore
    element.addEventListener('live:connect', onConnect);
    // @ts-ignore
    element.addEventListener('live:disconnect', onDisconnect);
}

function disconnectListenersFromElement(element: HTMLElement): void {
    // @ts-ignore
    element.removeEventListener('live:connect', onConnect);
    // @ts-ignore
    element.removeEventListener('live:disconnect', onDisconnect);
}

export function LiveComponentAwareController<Base extends Constructor<Controller<HTMLElement>>>(Base: Base) {
    const derived = class extends Base
    {
    };

    if (typeof derived.prototype.connect === 'function') {
        const originalConnect = derived.prototype.connect;
        derived.prototype.connect = function (): void {
            const element = this.element;
            connectedElements.add(element);
            disconnectListenersFromElement(element);
            connectListenersToElement(element);
            elementToControllerMap.set(element, this as unknown as LiveComponentAwareController);
            if (typeof originalConnect === 'function') {
                originalConnect.call(this);
            }
        }
    }
    if (typeof derived.prototype.disconnect === 'function') {
        const originalDisconnect = derived.prototype.disconnect;
        derived.prototype.disconnect = function (): void {
            if (typeof originalDisconnect === 'function') {
                originalDisconnect.call(this);
            }
            const element = this.element;
            elementToControllerMap.delete(element);
            connectedElements.delete(element);
            setTimeout(() => {
                if (!connectedElements.has(element)) {
                    disconnectListenersFromElement(element);
                }
            });
        }
    }
    return derived as unknown as Base & Constructor<LiveComponentAwareController>
}
