import {Typed} from "app/stimulus/typed-stimulus";
import {Controller} from "@hotwired/stimulus";

export default class extends Typed(
    Controller<HTMLElement>, {
        classes: [
            'scopedLoading',
            'loading'
        ] as const,
    }
)
{
    private isFocused: boolean = false;
    private observer: MutationObserver | null = null;

    public initialize(): void {
        this.initializeObserver();
    }

    public connect(): void {
        this.connectObserver();
    }

    public disconnect(): void {
        this.disconnectObserver();
    }

    public markAsFocused(): void {
        this.isFocused = true;
    }

    private initializeObserver(): void {
        this.observer = new MutationObserver(() => {
            if (!this.hasLoadingClass || !this.hasScopedLoadingClass) {
                return;
            }
            let addClass = false;
            let removeClass = false;
            if (
                this.isFocused
                && this.element.classList.contains(this.scopedLoadingClass)
                && !this.element.classList.contains(this.loadingClass)
            ) {
                addClass = true;
            } else if (
                !this.element.classList.contains(this.scopedLoadingClass)
                && this.element.classList.contains(this.loadingClass)
            ) {
                removeClass = true;
            }
            this.isFocused = false;
            if (addClass || removeClass) {
                try {
                    this.disconnectObserver();
                    if (addClass) {
                        this.element.classList.add(this.loadingClass);
                    } else {
                        this.element.classList.remove(this.loadingClass);
                    }
                } finally {
                    this.connectObserver();
                }
            }
        });
    }

    private connectObserver(): void {
        this.observer?.observe(this.element, {attributes: true, attributeFilter: ['class']});
    }

    private disconnectObserver(): void {
        this.observer?.disconnect();
    }
}
